export const MAX_REFERRALS_PER_PAGE = 25;

export const REFERRAL_CLAIM_OUTCOMES = {
  available: 'available',
  alreadyClaimed: 'alreadyClaimed',
  teamClaimed: 'teamClaimed',
  ineligible: 'ineligible',
  claimFailed: 'claimFailed',
  anotherAgentCall: 'anotherAgentCall',
};

export type PropertyMappingType =
  | 'all'
  | 'favorites'
  | 'recently_viewed'
  | 'requested_tour';

export const INTERESTED_PROPERTY_TYPE_COPY = {
  requested_tour: 'Requested',
  recently_viewed: 'Viewed',
  favorites: 'Saved',
};

export default {};
