import { PUBLIC_PAGES, TOKENIZED_PAGES } from '@hl-portals/constants';
import { ParsedUrlQuery } from 'querystring';

const sessionAuthRequired = (params: ParsedUrlQuery, pathname?: string) => {
  if (!pathname) return true;

  const paramTokens = ['token', 'signature_token'];
  const tokenPresent = paramTokens.some((name: string) => !!params[name]);

  return !PUBLIC_PAGES.includes(pathname) && !(TOKENIZED_PAGES.includes(pathname) && tokenPresent);
};

export default sessionAuthRequired;
