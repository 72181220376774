import styled, { css } from 'styled-components';

import {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import InputMask from 'react-input-mask';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { theme } from '@hl-portals/constants';

import { paragraphHelper } from '@hl-portals/helpers';

import { SlideDown } from '../Animations';
import { Box, BoxTypes } from '../Box';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';

type InputVariantTypes = 'normal' | 'search' | 'currency' | 'percent';

export type InputProps = {
  id?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onClear?: () => void;
  variant?: InputVariantTypes;
  isError?: boolean;
  autoComplete?: 'on' | 'off';
  'data-test'?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  list?: string;
  numberFormatProps?: NumberFormatProps;
  css?: { [property: string]: any };
  mask?: string;
  borderColor?: string;
  hint?: string;
  hintStyle?: 'info' | 'warning';
  keepHint?: boolean;
} & BoxTypes;

const InputType = styled.div<{ variant: InputVariantTypes }>`
  ${({ variant }) => css`
    position: absolute;
    top: ${variant === 'search' ? '2px' : '0'};
    left: 0;
    width: 36px;
    height: calc((1.4 * 14px) + 24px);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${variant === 'search' &&
      css`
        width: 20px;
        height: 20px;
        background-image: url('/search.svg');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
      `}
      ${variant === 'currency' &&
      css`
          content: '$';
          color: ${theme.colors.coolGray2};
          ${paragraphHelper({ type: 'text', theme })}
        }
      `}
      ${variant === 'percent' &&
      css`
          content: '%';
          color: ${theme.colors.coolGray2};
          ${paragraphHelper({ type: 'text', theme })}
        }
      `}
    }
  `}
`;

const InputFieldCss = css<{
  variant: InputVariantTypes;
  $isError: boolean;
  disabled: boolean;
  $hasOnClear?: boolean;
  $borderColor?: string;
  boxSizing?: string;
}>`
  ${({
    variant,
    $isError,
    disabled,
    $hasOnClear,
    $borderColor,
    boxSizing,
  }) => css`
    -webkit-appearance: none;
    -moz-appearance: none;
    ${boxSizing ? `box-sizing: ${boxSizing};` : ''}
    appearance: none;
    flex-grow: 1;
    margin: 0;
    width: 100%;
    padding: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 6px;
    background-color: #fff;
    border: 2px solid ${$borderColor || theme.colors.coolGray5};
    color: ${theme.colors.darkBlue};
    ${paragraphHelper({ type: 'text-small', theme })}
    ${$isError &&
    css`
      padding: 0;
    `}
  ${variant !== 'normal' &&
    css`
      padding-left: 36px;
    `}

  ${$hasOnClear &&
    css`
      padding-right: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${$isError
      ? css`
          padding: 11px;
          ${$hasOnClear &&
          css`
            padding-right: 40px;
          `}
          ${variant !== 'normal' &&
          css`
            padding-left: 35px;
          `}
        border: 2px solid ${theme.colors.cadmiumRed};
        `
      : css`
    &:focus {
      padding: 11px;
      ${
        variant !== 'normal' &&
        css`
          padding-left: 35px;
        `
      }
      border: 2px solid ${theme.colors.electricBlue};
    }}
  `}

  ${disabled &&
    css`
      background-color: ${theme.colors.coolGray6};
    `}

  &::-webkit-input-placeholder {
      color: ${theme.colors.coolGray2};
    }

    &::-moz-placeholder {
      color: ${theme.colors.coolGray2};
    }

    &:focus {
      outline: none;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
    }

    &:focus::-moz-placeholder {
      color: transparent;
    }
  `}
`;

const InputField = styled.input`
  ${InputFieldCss}
`;

const InputMaskStyled = styled(InputMask)`
  ${InputFieldCss}
`;

const IconWrapper = styled(Box)`
  position: absolute;
  align-items: center;
  top: 0;
  right: 8px;
  bottom: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      variant = 'normal',
      'data-test': dataTest,
      isError = false,
      disabled = false,
      autoComplete = 'off',
      onClick,
      onClear,
      value,
      type,
      mt,
      mb,
      mr,
      ml,
      m,
      height,
      width = '100%',
      minWidth,
      maxWidth = '100%',
      marginTop,
      textAlign,
      id,
      numberFormatProps,
      mask,
      placeholder,
      borderColor,
      hint,
      hintStyle = 'info',
      keepHint,
      ...inputProps
    },
    forwardedRef
  ): React.ReactElement => {
    const boxProps = { height, width, mt, mb, mr, ml, m, marginTop };

    const [localType, setLocalType] = useState(type);
    const [focused, setFocused] = useState(false);

    const onInputFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      if (typeof inputProps.onFocus === 'function') inputProps.onFocus(e);

      setFocused(true);
    };

    const onInputBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      if (typeof inputProps.onBlur === 'function') inputProps.onBlur(e);

      setFocused(false);
    };

    const overrideProps = {
      onFocus: onInputFocus,
      onBlur: onInputBlur,
    };

    const forwardedProps = {
      ...inputProps,
      ...overrideProps,
    };

    const CustomNumberFormat = useMemo(
      () => (p: any) =>
        (
          <InputField
            variant={variant}
            $isError={isError}
            autoComplete={autoComplete}
            onClick={onClick}
            data-test={dataTest}
            id={id}
            type={type}
            $hasOnClear={Boolean(onClear)}
            $borderColor={borderColor}
            {...p}
            {...inputProps}
          />
        ),
      [variant, isError, autoComplete, onClick, dataTest, id, type, borderColor]
    );

    const showHint = keepHint ? true : hint && focused;

    const hintStyles =
      hintStyle === 'info'
        ? {
            fill: 'electricBlue',
            color: '#273653',
            bgcolor: theme.colors.gray50,
          }
        : {
            fill: undefined,
            color: '#D64F00',
            bgcolor: '#FFF6EB',
          };

    return (
      <Box
        boxSizing="border-box"
        flexWrap="nowrap"
        flexDirection={showHint ? 'column' : 'row'}
        minWidth={minWidth}
        maxWidth={maxWidth}
        margin={0}
        position="relative"
        {...boxProps}
      >
        {mask ? (
          <InputMaskStyled
            variant={variant}
            placeholder={placeholder}
            value={value}
            $isError={isError}
            data-test={dataTest}
            mask={mask}
            ref={forwardedRef}
            $borderColor={borderColor}
            $hasOnClear={Boolean(onClear)}
            alwaysShowMask={false}
            {...forwardedProps}
          />
        ) : (
          <>
            {variant !== 'normal' && <InputType variant={variant} />}
            {numberFormatProps && (
              <NumberFormat
                inputRef={forwardedRef}
                thousandSeparator
                isNumericString
                variant={variant}
                $isError={isError}
                disabled={disabled}
                autoComplete={autoComplete}
                onClick={onClick}
                data-test={dataTest}
                id={id}
                placeholder={placeholder}
                {...forwardedProps}
                type="text"
                value={value}
                {...numberFormatProps}
                customInput={CustomNumberFormat}
                $borderColor={borderColor}
              />
            )}
            {!numberFormatProps && (
              <InputField
                variant={variant}
                $isError={isError}
                disabled={disabled}
                autoComplete={autoComplete}
                onClick={onClick}
                $hasOnClear={Boolean(onClear)}
                data-test={dataTest}
                ref={forwardedRef}
                value={value}
                id={id}
                type={localType}
                placeholder={placeholder}
                $borderColor={borderColor}
                {...forwardedProps}
              />
            )}
            {type === 'password' && (
              <IconWrapper
                onClick={() =>
                  setLocalType((t) => (t === 'text' ? 'password' : 'text'))
                }
                data-test="input-clear-button"
              >
                <Icon
                  type={localType === 'password' ? 'eye' : 'eyeSlash'}
                  fill="darkBlue"
                />
              </IconWrapper>
            )}
          </>
        )}
        {!!value && onClear && !showHint && (
          <IconWrapper onClick={onClear} data-test="input-clear-button">
            <Icon type="timesCircle" size={24} fill="coolGray4" />
          </IconWrapper>
        )}
        {showHint && (
          <SlideDown
            bgcolor={hintStyles.bgcolor}
            p="12px"
            gap="8px"
            width="100%"
            borderRadius="8px"
          >
            {hintStyles.fill && (
              <Icon type="infoSolid" fill={hintStyles.fill} size={16} />
            )}
            <Paragraph variant="text-small" color={hintStyles.color}>
              {hint}
            </Paragraph>
          </SlideDown>
        )}
      </Box>
    );
  }
);
